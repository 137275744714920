import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import params from "jquery-param";

import req from "../../../../../utilities/request-utility";

const useOverviewData = ({ isAdmin }) => {
  const {
    libraryFileQuery,
    libraryFolderQuery,
    libraryURLQuery,
    manageLibraryFileQuery,
    manageLibraryFolderQuery,
    manageLibraryURLQuery,
    selectedFolder,
    selectedLibraryPath,
    selectedManageLibraryPath,
  } = useSelector(({ library }) => ({
    libraryFileQuery: library.libraryFileQuery,
    libraryFolderQuery: library.libraryFolderQuery,
    libraryURLQuery: library.libraryURLQuery,
    manageLibraryFileQuery: library.manageLibraryFileQuery,
    manageLibraryFolderQuery: library.manageLibraryFolderQuery,
    manageLibraryURLQuery: library.manageLibraryURLQuery,
    selectedFolder: library.selectedFolder,
    selectedLibraryPath: library.selectedLibraryPath,
    selectedManageLibraryPath: library.selectedManageLibraryPath,
  }));

  const libraryPathKey = selectedLibraryPath.split("/")[selectedLibraryPath.split("/").length - 1];
  const manageLibraryPathKey = selectedManageLibraryPath.split("/")[selectedManageLibraryPath.split("/").length - 1];
  const pathKey = libraryPathKey !== "" ? libraryPathKey : manageLibraryPathKey !== "" ? manageLibraryPathKey : "";

  const {
    data: { data: folderData = [] } = [],
    error: folderError,
    isFetching: folderIsFetching,
    isLoading: folderIsLoading,
    refetch: folderRefetch,
  } = useQuery(
    `${
      selectedFolder
        ? `${selectedFolder.id}-${isAdmin ? manageLibraryFolderQuery.active : libraryFolderQuery.active}-${pathKey}`
        : `folder-${isAdmin ? manageLibraryFolderQuery.active : libraryFolderQuery.active}-${pathKey}`
    }-library-folders`,
    () => {
      return req()(`semcompletion/library/folders?${params(isAdmin ? manageLibraryFolderQuery : libraryFolderQuery)}`);
    }
  );

  const {
    data: { data: folderOptionsData = [] } = [],
    error: folderOptionsError,
    isFetching: folderOptionsIsFetching,
    isLoading: folderOptionsIsLoading,
    refetch: folderOptionsRefetch,
  } = useQuery(`folder-options-${pathKey}`, () => req()(`semcompletion/library/folders/options`));

  const folderOptions = {
    data: folderOptionsData,
    fetching: folderOptionsIsFetching,
    loading: folderOptionsIsLoading,
    error: folderOptionsError,
    refetch: folderOptionsRefetch,
  };

  const folders = {
    data: folderData,
    fetching: folderIsFetching,
    loading: folderIsLoading,
    error: folderError,
    refetch: folderRefetch,
  };

  const {
    data: { data: folderFilesData = [] } = [],
    error: folderFilesError,
    isFetching: folderFilesIsFetching,
    isLoading: folderFilesIsLoading,
    refetch: folderFilesRefetch,
  } = useQuery(
    `${
      selectedFolder
        ? `${selectedFolder.id}-${isAdmin ? manageLibraryFileQuery.active : libraryFileQuery.active}-${pathKey}`
        : `folder-${isAdmin ? manageLibraryFileQuery.active : libraryFileQuery.active}-${pathKey}`
    }-library-files`,
    () => {
      if (selectedFolder)
        return req()(
          `semcompletion/library/files/${selectedFolder.id}?${params(isAdmin ? manageLibraryFileQuery : libraryFileQuery)}`
        );
    }
  );

  const folderFiles = {
    data: folderFilesData,
    fetching: folderFilesIsFetching,
    loading: folderFilesIsLoading,
    error: folderFilesError,
    refetch: folderFilesRefetch,
  };

  const {
    data: { data: folderFileOptionsData = [] } = [],
    error: folderFileOptionsError,
    isFetching: folderFileOptionsIsFetching,
    isLoading: folderFileOptionsIsLoading,
    refetch: folderFileOptionsRefetch,
  } = useQuery(`file-options-${pathKey}`, () => req()(`semcompletion/library/files/options`));

  const folderFileOptions = {
    data: folderFileOptionsData,
    fetching: folderFileOptionsIsFetching,
    loading: folderFileOptionsIsLoading,
    error: folderFileOptionsError,
    refetch: folderFileOptionsRefetch,
  };

  const {
    data: { data: folderUrlOptionsData = [] } = [],
    error: folderUrlOptionsError,
    isFetching: folderUrlOptionsIsFetching,
    isLoading: folderUrlOptionsIsLoading,
    refetch: folderUrlOptionsRefetch,
  } = useQuery(`url-options-${pathKey}`, () => req()(`semcompletion/library/urls/options`));

  const folderUrlOptions = {
    data: folderUrlOptionsData,
    fetching: folderUrlOptionsIsFetching,
    loading: folderUrlOptionsIsLoading,
    error: folderUrlOptionsError,
    refetch: folderUrlOptionsRefetch,
  };

  const {
    data: { data: folderUrlsData = [] } = [],
    error: folderUrlsError,
    isFetching: folderUrlsIsFetching,
    isLoading: folderUrlsIsLoading,
    refetch: folderUrlsRefetch,
  } = useQuery(
    `${
      selectedFolder
        ? `${selectedFolder.id}-${isAdmin ? manageLibraryURLQuery.active : libraryURLQuery.active}-${pathKey}`
        : `folder-${isAdmin ? manageLibraryURLQuery.active : libraryURLQuery.active}-${pathKey}`
    }-library-urls`,
    () => {
      if (selectedFolder)
        return req()(
          `semcompletion/library/urls/${selectedFolder.id}?${params(isAdmin ? manageLibraryURLQuery : libraryURLQuery)}`
        );
    }
  );

  const folderUrls = {
    data: folderUrlsData,
    fetching: folderUrlsIsFetching,
    loading: folderUrlsIsLoading,
    error: folderUrlsError,
    refetch: folderUrlsRefetch,
  };

  return { folderFiles, folderOptions, folderFileOptions, folderUrlOptions, folders, folderUrls };
};

export default useOverviewData;
