import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";
import { Button as AntButton, notification, Upload } from "antd";
import { DeleteIcon, FilePdfIcon, InboxIcon, VideoIcon } from "mdi-react";
import { isEqual, keyBy, uniqBy } from "lodash";
import params from "jquery-param";

import colors from "../../../../style/colors";

import Button from "../../../ui/Button";
import DropDown from "../../../ui/DropDown";
import FormItem from "../../../ui/FormItem";
import ScrollView from "../../../ui/ScrollView";
import TextInput from "../../../ui/TextInput";

import req from "../../../../utilities/request-utility";
import getErrorMessage from "../../../../utilities/get-error-message";

import { hideModalPage } from "../../../../actions/uiActions";

const UploadForm = ({
  action = null,
  currentFile = null,
  currentURL = null,
  folderFilesRefetch = () => {},
  folderOptions = [],
  folderRefetch = () => {},
  folderUrlsRefetch = () => {},
}) => {
  const dispatch = useDispatch();

  const { selectedFolder, selectedProject } = useSelector(
    ({ library, semcompletion }) => ({
      selectedFolder: library.selectedFolder,
      selectedProject: semcompletion.selectedProject,
    }),
    isEqual
  );

  const [fileList, setFileList] = useState(currentFile ? [{ name: currentFile.fileName, uid: currentFile.id }] : []);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [folder, setFolder] = useState(selectedFolder.id);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [urlData, setUrlData] = useState(
    currentURL ? [{ textToDisplay: currentURL.textToDisplay, url: currentURL.url }] : [{ textToDisplay: "", url: "" }]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfURLs, setNumberOfURLs] = useState(1);
  const [options, setOptions] = useState(folderOptions);

  const handleUrlChange = useCallback(
    (e, i) => {
      const newUrlData = [...urlData];
      newUrlData[i][e.target.name] = e.target.value;
      setUrlData(newUrlData);
    },
    [urlData]
  );

  const acceptedFiles = useMemo(() => {
    if (!currentFile) return ".pdf, .mp4, .mov, application/pdf, video/mp4, video/quicktime";

    if (currentFile.fileName.includes(".pdf")) return ".pdf, application/pdf";

    if (currentFile.fileName.includes(".mp4") || currentFile.fileName.includes(".mov"))
      return ".mp4, .mov, video/mp4, video/quicktime";
  }, [currentFile]);

  const urlInputFields = useMemo(() => {
    const numberOfURLsArr = Array(parseInt(numberOfURLs)).fill(0);

    return numberOfURLsArr.map((d, i) => (
      <div key={i} style={{ display: "flex", marginBottom: 10 }}>
        <TextInput
          name="url"
          onChange={(e) => handleUrlChange(e, i)}
          placeholder="www.example.com"
          type="url"
          value={urlData[i].url}
        />
        <TextInput
          name="textToDisplay"
          onChange={(e) => handleUrlChange(e, i)}
          placeholder="Enter Text to Display"
          suffix={
            numberOfURLsArr.length > 1 ? (
              <AntButton
                onClick={() => {
                  const newUrlData = [...urlData];
                  newUrlData.splice(i, 1);

                  setUrlData(newUrlData);
                  setNumberOfURLs(numberOfURLs - 1);
                }}
                type="text"
              >
                <DeleteIcon />
              </AntButton>
            ) : null
          }
          value={urlData[i].textToDisplay}
        />
      </div>
    ));
  }, [numberOfURLs, urlData]);

  const isURLValid = useCallback(() => {
    const errors = [];

    urlData.map((u) => {
      if (!u.url.match(/^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/\S*)?$/)) errors.push(u.url);
    });

    return errors.length > 0 ? false : true;
  }, [urlData]);

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);

      if (selectedFolder) {
        if (!currentFile && !currentURL) {
          if (uploadedFile.length !== 0) {
            const uploadRequests = uploadedFile
              .filter((file) => !removedFiles.find((r) => r.name === file.fileName))
              .map((file) => req().post(`semcompletion/library/files/${selectedFolder.id}`, { file }));

            await Promise.all(uploadRequests);

            folderFilesRefetch();

            setIsLoading(false);
            dispatch(hideModalPage());

            return notification.success({ duration: 7, message: "SUCCESS", description: "Library file created" });
          }

          if (urlData.length > 0 && urlData.filter((u) => u.url === "").length === 0) {
            const urlValidation = isURLValid(urlData);

            if (!urlValidation) {
              setIsLoading(false);
              return notification.error({ duration: 7, message: "ERROR", description: "Invalid URL" });
            }

            const urlRequests = urlData.map((u) =>
              req().post(`semcompletion/library/urls/${selectedFolder.id}`, { textToDisplay: u.textToDisplay, url: u.url })
            );

            await Promise.all(urlRequests);

            folderUrlsRefetch();

            setIsLoading(false);
            dispatch(hideModalPage());

            return notification.success({ duration: 7, message: "SUCCESS", description: "Library URL created" });
          }
        }

        if (currentFile && uploadedFile.length !== 0) {
          const uploadRequests = uploadedFile
            .filter((file) => !removedFiles.find((r) => r.name === file.fileName))
            .map((file) =>
              req().put(`semcompletion/library/files/${currentFile.id}`, {
                file,
                folder,
              })
            );

          await Promise.all(uploadRequests);

          folderRefetch();
          folderFilesRefetch();
          dispatch(hideModalPage());

          return notification.success({ duration: 7, message: "SUCCESS", description: "Library file updated" });
        }

        if (currentFile && uploadedFile.length === 0) {
          const { data: updatedFile } = await req().put(`semcompletion/library/files/${currentFile.id}`, {
            folder,
          });

          folderRefetch();
          folderFilesRefetch();
          dispatch(hideModalPage());

          return notification.success({ duration: 7, message: "SUCCESS", description: updatedFile.message });
        }

        if (currentURL && urlData.filter((u) => u.url === "").length === 0) {
          const urlValidation = isURLValid(urlData);

          if (!urlValidation) {
            setIsLoading(false);
            return notification.error({ duration: 7, message: "ERROR", description: "Invalid URL" });
          }

          const { data: updatedUrl } = await req().put(`semcompletion/library/urls/${currentURL.id}`, {
            folder,
            textToDisplay: urlData[0].textToDisplay,
            url: urlData[0].url,
          });

          folderRefetch();
          folderUrlsRefetch();
          dispatch(hideModalPage());

          return notification.success({ duration: 7, message: "SUCCESS", description: updatedUrl.message });
        }
      }

      setIsLoading(false);
      dispatch(hideModalPage());
    } catch (error) {
      setIsLoading(false);
      notification.error({ duration: 7, message: "FAILED", description: getErrorMessage(error) });
    }
  }, [selectedProject, selectedFolder, uploadedFile, urlData, folder]);

  const handleUploadChange = useCallback(
    async ({ fileList }) => {
      if (!currentFile)
        return setFileList(
          uniqBy(
            fileList.filter((f) => (f.type !== "" ? acceptedFiles.includes(f.type) : false)),
            "name"
          )
        );

      if (fileList.length === 1)
        return setFileList(
          uniqBy(
            fileList.filter((f) => (f.type !== "" ? acceptedFiles.includes(f.type) : false)),
            "name"
          )
        );

      const shiftedFile = fileList.shift();
      setFileList(
        uniqBy(
          fileList.filter((f) => (f.type !== "" ? acceptedFiles.includes(f.type) : false)),
          "name"
        )
      );

      if (shiftedFile)
        await req().delete(
          `semcompletion/library/files/${selectedProject.number}/remove?${params({ fileName: shiftedFile.name })}`
        );
    },
    [currentFile, acceptedFiles]
  );

  const handleDropdownChange = useCallback((e) => setFolder(e.target.value), []);

  const handleRemoveFile = useCallback(
    async (d) => {
      await req().delete(`semcompletion/library/files/${selectedProject.number}/remove?${params({ fileName: d.name })}`);

      setUploadedFile((prev) => prev.filter((file) => file.fileName !== d.name));
      setRemovedFiles((prev) => [...prev, d]);
    },
    [fileList]
  );

  const handleBeforeUpload = useCallback(
    (file, fileList) => {
      let unacceptedFiles = [];

      fileList.map((f) => {
        if (!acceptedFiles.includes(f.type) || f.type === "") unacceptedFiles.push(f);
      });

      if (fileList.length === 1 && unacceptedFiles.length > 0)
        notification.error({
          duration: 7,
          message: "FILE NOT SUPPORTED",
          description: `We cannot upload the file. It is not in a supported format. Please select another file or change its format.`,
        });

      if (fileList.length > 1 && unacceptedFiles.length > 0)
        notification.error({
          duration: 7,
          message: `${unacceptedFiles.length} FILES DID NOT UPLOAD`,
          description: `We cannot upload some of the files. They are not in a supported format. Please select different files or change their format.`,
        });

      if (!acceptedFiles.includes(file.type) || file.type === "") return false;

      return true;
    },
    [acceptedFiles]
  );

  const uploadFile = useCallback(
    async (options) => {
      const { file, onProgress } = options;

      const formData = new FormData();
      formData.append("file", file);

      const config = {
        onUploadProgress: (event) => onProgress({ percent: (event.loaded / event.total) * 100 }),
      };

      const { data: uploadedFile } = await req(config).post(
        `semcompletion/library/${selectedProject.number}/upload`,
        formData
      );

      setUploadedFile((prev) => [...prev, uploadedFile]);
    },
    [fileList]
  );

  const getNewFolderOptions = useCallback(
    (id) => {
      const childFolders = folderOptions.filter((d) => d.parentFolder === id);

      if (childFolders.length === 0) return null;

      let childFoldersIds = [];

      childFolders.map((d) => {
        childFoldersIds.push(d.id);

        const childIdArr = getNewFolderOptions(d.id);
        if (childIdArr) childFoldersIds = [...childFoldersIds, ...childIdArr];
      });

      return childFoldersIds;
    },
    [folderOptions]
  );

  useEffect(() => {
    let archivedFolderIds = [];

    let newFolderOptions = [];

    folderOptions
      .filter((d) => !d.active)
      .map((d) => {
        archivedFolderIds.push(d.id);

        const childFolderIds = getNewFolderOptions(d.id);
        if (childFolderIds) archivedFolderIds = [...archivedFolderIds, ...childFolderIds];
      });

    newFolderOptions = folderOptions.filter((d) => !archivedFolderIds.includes(d.id));

    setOptions(newFolderOptions);
  }, []);

  useEffect(() => {
    if (uploadedFile.length !== 0) {
      const newFileList = fileList.map((f) => {
        uploadedFile.map((u) => {
          if (f.name === u.fileName) f.status = "done";
        });

        return f;
      });

      setFileList(newFileList);
    }
  }, [uploadedFile]);

  useEffect(() => {
    const filteredRemovedFiles = removedFiles.filter((d) => d.status === "removed");
    const uploadedFileObj = keyBy(uploadedFile, "fileName");

    if (filteredRemovedFiles.length > 0) {
      filteredRemovedFiles.map(async (d) => {
        if (d.status === "removed" && uploadedFileObj[d.name]) {
          await req().delete(`semcompletion/library/files/${selectedProject.number}/remove?${params({ fileName: d.name })}`);
        }
      });
    }
  }, [uploadedFile]);

  return (
    <ScrollView className={container()}>
      {(currentFile || currentURL) && (
        <div className="row">
          <div className="col-100">
            <FormItem label="Folder">
              <DropDown
                allowClear={false}
                name="folder"
                onChange={handleDropdownChange}
                options={options.map((d) => ({ label: d.name, value: d.id }))}
                placeholder="Click to choose"
                value={folder}
              />
            </FormItem>
          </div>
        </div>
      )}
      {(currentFile || (!currentFile && action === "Add Files")) && (
        <div className="row">
          <div className="col-100">
            <FormItem label="Upload Files">
              <Upload.Dragger
                beforeUpload={handleBeforeUpload}
                customRequest={uploadFile}
                fileList={fileList}
                iconRender={(file, listType) => (file.name.includes(".pdf") ? <FilePdfIcon /> : <VideoIcon />)}
                listType="picture"
                multiple={!currentFile ? true : false}
                onChange={handleUploadChange}
                onRemove={handleRemoveFile}
              >
                <p className="ant-upload-drag-icon">
                  <InboxIcon className="inbox-icon" />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Supported files:{" "}
                  {acceptedFiles
                    .split(", ")
                    .filter((d) => d.includes("."))
                    .join(", ")}
                </p>
              </Upload.Dragger>
            </FormItem>
          </div>
        </div>
      )}
      {(currentURL || (!currentFile && action === "Add URL")) && (
        <div className="row">
          <div className="col-100">
            <FormItem label="URL" required={true}>
              {urlInputFields}
            </FormItem>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-100">
          {!currentURL && action === "Add URL" && (
            <Button
              disabled={isLoading}
              onClick={() => {
                setNumberOfURLs((prev) => prev + 1);
                setUrlData((prev) => [...prev, { textToDisplay: "", url: "" }]);
              }}
              style={{ marginBottom: 5 }}
            >
              Add More URLs
            </Button>
          )}
          <Button
            active={isLoading}
            disabled={
              (fileList.length === 0 && urlData.filter((u) => u.url === "").length !== 0) ||
              isLoading ||
              fileList.filter((d) => d.status === "uploading").length !== 0
            }
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </ScrollView>
  );
};

const container = () => css`
  background-color: ${colors.white};
  padding: 1rem 0;

  .ant-upload-list-item-progress {
    width: calc(98% - 24px);
  }

  .inbox-icon {
    width: 40px;
    height: 40px;
  }

  label {
    font-weight: 700;
    margin-bottom: 0.35rem;
    display: block;
  }

  .row {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto 1.25rem auto;

    .col-50 {
      flex-basis: 49%;
    }

    .col-100 {
      flex-basis: 100%;
    }

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;

      .col-50 {
        flex-basis: 100%;

        &:nth-child(odd) {
          margin-bottom: 1.25rem;
        }
      }
    }
  }
`;

export default memo(UploadForm);
