import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { DatePicker, notification, Typography } from "antd";
import { AccountAlertIcon, AccountCheckIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon } from "mdi-react";
import AnimateHeight from "react-animate-height";
import moment from "moment";

// styling
import { css } from "emotion";
import colors from "../../../style/colors";
//actions

import PunchStatus from "./PunchStatus";

import ImageUploadMultiple from "../ImageUploadMultiple";

import ButtonRounded from "../../ui/ButtonRounded";
import FormItem from "../../ui/FormItem";
import InlineSpinner from "../../ui/InlineSpinner";
import TextInput from "../../ui/TextInput";
import ScrollView from "../../ui/ScrollView";

import punchStatuses from "../../../config/punchStatuses";
import req from "../../../utilities/request-utility";

function PunchSingleHeader({ punch, punchActions, getPunch }) {
  const [isFormVisible, setIsFormVisibile] = useState(null);

  const { user = null } = useSelector(({ auth }) => ({ user: auth.user }));

  const isTabletPortrait = useMediaQuery({ orientation: "portrait", maxWidth: 820 });
  const isIpadMiniLandscape = useMediaQuery({ orientation: "landscape", minWidth: 900, maxWidth: 1180 });

  const height = useMemo(() => {
    if (isFormVisible === "clear-to-close") return 343;
    return 495;
  }, [isFormVisible]);

  return (
    <ScrollView
      className={
        container({ isFormVisible, isTabletPortrait }) + ` ${punch.status} ${isFormVisible ? "form-visible" : "form-hidden"}`
      }
      minHeight={isFormVisible ? height : null}
      isPunchFormOpen={isFormVisible}
    >
      {/* Form for open punches */}
      {punch.status === punchStatuses.open && (
        <OpenPunchForm
          getPunch={getPunch}
          isFormVisible={isFormVisible}
          setIsFormVisibile={setIsFormVisibile}
          punch={punch}
          punchActions={punchActions}
          isTabletPortrait={isTabletPortrait}
          isIpadMiniLandscape={isIpadMiniLandscape}
          user={user}
        />
      )}

      {/* Form for Cleared punches */}
      {punch.status === punchStatuses.cleared && (
        <ClearPunchForm
          getPunch={getPunch}
          isFormVisible={isFormVisible}
          setIsFormVisibile={setIsFormVisibile}
          punch={punch}
          punchActions={punchActions}
          isTabletPortrait={isTabletPortrait}
          isIpadMiniLandscape={isIpadMiniLandscape}
          user={user}
        />
      )}

      {/* Closed punches is just closed */}
      {punch.status === punchStatuses.closed && (
        <ClosePunchForm
          getPunch={getPunch}
          isFormVisible={isFormVisible}
          punch={punch}
          punchActions={punchActions}
          setIsFormVisibile={setIsFormVisibile}
          user={user}
        />
      )}
    </ScrollView>
  );
}

function OpenPunchForm({
  isFormVisible,
  setIsFormVisibile,
  punch,
  punchActions,
  getPunch,
  isTabletPortrait,
  isIpadMiniLandscape,
  user,
}) {
  const [formData, setFormData] = useState({
    name: user ? user.name : "",
    date: moment().format("YYYY-MM-DD"),
    comment: "",
    images: undefined,
  });
  const [formLoading, setFormloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const getUploadState = (uploadState) => setIsUploading(uploadState);

  function onChange(e, formData) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  function clearPunch(formData) {
    let apiReadyData = {
      ...formData,
      date: formData.date.replace(/-/g, ""),
    };

    if (!formData.name || !formData.date || !formData.comment)
      return notification.error({
        duration: 7,
        message: "FAILED",
        description: "Some fields are missing. Make sure to fill out name, date and comment and try again",
      });

    setFormloading(true);

    req()
      .put(`semcompletion/v2/punches/${punch.id}/clear`, apiReadyData)
      .then(({ data }) => {
        setIsFormVisibile(null);
        setTimeout(getPunch, 300);
        setFormloading(false);
      })
      .catch((err) => {
        console.log(err);
        setFormloading(false);
      });
  }

  const disabledDate = (current) => current && current < moment().subtract(1, "day");

  return (
    <div>
      <div className="actions row">
        <div className="left">
          <PunchStatus status={punch.status} />
        </div>
        <div className="center">
          {!isFormVisible && (
            <ButtonRounded onClick={() => setIsFormVisibile("open-to-clear")} className="btn-show-form" secondary={true}>
              <ChevronDownIcon /> Show clear punch form
            </ButtonRounded>
          )}
          {isFormVisible && (
            <ButtonRounded onClick={() => setIsFormVisibile(null)} className="btn-hide-form" secondary={true}>
              <ChevronUpIcon /> Hide clear punch form
            </ButtonRounded>
          )}
        </div>
        <div className="right">
          {((isFormVisible && punchActions["Clear Punch"] && punchActions["Clear Punch"].active) ||
            (isFormVisible && !punchActions["Clear Punch"])) && (
            <ButtonRounded
              color={colors.vividBlue}
              disabled={formLoading || isUploading}
              onClick={() => clearPunch(formData)}
            >
              {formLoading ? (
                <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
              ) : (
                <AccountCheckIcon />
              )}{" "}
              Clear punch
            </ButtonRounded>
          )}
        </div>
      </div>

      <AnimateHeight animateOpacity={true} height={isFormVisible ? "auto" : 0}>
        {/* If closed show clear form */}
        <div className="row">
          <div className="col-50">
            <FormItem label="Name" required={true}>
              <TextInput name="name" value={formData.name} onChange={(e) => onChange(e, formData)} />
            </FormItem>
          </div>

          <div className="col-50">
            <FormItem label="Date" required={true}>
              <DatePicker
                allowClear={false}
                format="YYYY-MM-DD"
                disabledDate={disabledDate}
                onChange={(date, dateString) => onChange({ target: { name: "date", value: dateString } }, formData)}
                size="large"
                style={{ width: "100%" }}
                value={formData.date ? moment(formData.date) : null}
              />
            </FormItem>
          </div>
        </div>

        <div className="row">
          <div className="col-100">
            <FormItem label="Comment" required={true}>
              <TextInput
                name="comment"
                maxLength={300}
                value={formData.comment}
                onChange={(e) => onChange(e, formData)}
                showCount={true}
                style={{ height: 120 }}
                type="textarea"
              />
            </FormItem>
          </div>
        </div>

        <div className="row">
          <div className="col-100">
            <FormItem label="Images and PDFs">
              <ImageUploadMultiple
                className="image-upload"
                placeholder="Upload Images and PDFs"
                urlPrefix="/semcompletion"
                uploadedFiles={formData.images}
                onFileUpdate={(files) => setFormData({ ...formData, images: files })}
                isTabletPortrait={isTabletPortrait}
                isIpadMiniLandscape={isIpadMiniLandscape}
                getUploadState={getUploadState}
              />
            </FormItem>
          </div>
        </div>
      </AnimateHeight>
    </div>
  );
}

const ClosePunchForm = ({ getPunch, isFormVisible, punch, punchActions, user, setIsFormVisibile }) => {
  const [formLoading, setFormloading] = useState(false);
  const [formData, setFormData] = useState({
    name: user ? user.name : "",
    comment: "",
    images: undefined,
  });
  const [isUploading, setIsUploading] = useState(false);

  const getUploadState = (uploadState) => setIsUploading(uploadState);

  const onChange = useCallback(
    (e, formData) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    },
    [setFormData]
  );

  const openPunch = useCallback(() => {
    if (!formData.name || !formData.comment)
      return notification.error({
        duration: 7,
        message: "FAILED",
        description: "Some fields are missing. Make sure to fill out name and comment and try again",
      });

    setFormloading(true);

    req()
      .put(`semcompletion/v2/punches/${punch.id}/open`, formData)
      .then(({ data }) => {
        setIsFormVisibile(null);
        setTimeout(getPunch, 300);
        setFormloading(false);
      })
      .catch((err) => {
        console.log(err);
        setFormloading(false);
      });
  }, [JSON.stringify(formData)]);

  return (
    <>
      <div className="actions row" style={{ margin: "0 auto" }}>
        <div className="left">
          <PunchStatus status={punch.status} />
        </div>
        <div className="center">
          {isFormVisible && (
            <ButtonRounded onClick={() => setIsFormVisibile(null)} className="btn-hide-form" secondary={true}>
              <ChevronUpIcon /> Hide open punch form
            </ButtonRounded>
          )}
        </div>
        <div className="right">
          {((!isFormVisible && punchActions["Open Punch"] && punchActions["Open Punch"].active) ||
            (!isFormVisible && !punchActions["Open Punch"])) && (
            <ButtonRounded
              color={colors.red}
              disabled={formLoading || isUploading}
              onClick={() => setIsFormVisibile("close-to-open")}
            >
              <AccountAlertIcon style={{ marginRight: "0.5rem", width: "1.25rem", height: "1.25rem" }} /> Open punch
            </ButtonRounded>
          )}
          {((isFormVisible && punchActions["Open Punch"] && punchActions["Open Punch"].active) ||
            (isFormVisible && !punchActions["Open Punch"])) && (
            <ButtonRounded color={colors.red} disabled={formLoading || isUploading} onClick={() => openPunch(formData)}>
              {formLoading ? (
                <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
              ) : (
                <AccountAlertIcon />
              )}{" "}
              Open punch
            </ButtonRounded>
          )}
        </div>
      </div>
      <AnimateHeight animateOpacity={true} height={isFormVisible ? "auto" : 0}>
        <div className="row">
          <div className="col-100">
            <FormItem label="Name" required={true}>
              <TextInput value={formData.name} onChange={(e) => onChange(e, formData)} name="name" />
            </FormItem>
          </div>
        </div>
        <div className="row">
          <div className="col-100">
            <FormItem label="Comment" required={true}>
              <TextInput
                name="comment"
                maxLength={300}
                value={formData.comment}
                onChange={(e) => onChange(e, formData)}
                showCount={true}
                style={{ height: 120 }}
                type="textarea"
              />
            </FormItem>
          </div>
        </div>
        <div className="row">
          <div className="col-100">
            <FormItem label="Images and PDFs" required={true}>
              <ImageUploadMultiple
                className="image-upload"
                placeholder="Upload Images and PDFs"
                urlPrefix="/semcompletion"
                uploadedFiles={formData.images}
                onFileUpdate={(files) => setFormData({ ...formData, images: files })}
                getUploadState={getUploadState}
              />
            </FormItem>
          </div>
        </div>
      </AnimateHeight>
    </>
  );
};

function ClearPunchForm({
  isFormVisible,
  setIsFormVisibile,
  punch,
  punchActions,
  getPunch,
  isTabletPortrait,
  isIpadMiniLandscape,
  user,
}) {
  const lang = useSelector((state) => state.language.language);

  const [formData, setFormData] = useState({
    name: user ? user.name : "",
    comment: "",
    images: undefined,
  });
  const [formLoading, setFormloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const getUploadState = (uploadState) => setIsUploading(uploadState);

  const dispatch = useDispatch();

  function onChange(e, formData) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  function openPunch(formData) {
    if (!formData.name || !formData.comment)
      return notification.error({
        duration: 7,
        message: "FAILED",
        description: "Some fields are missing. Make sure to fill out name and comment and try again",
      });

    setFormloading(true);

    req()
      .put(`semcompletion/v2/punches/${punch.id}/open`, formData)
      .then(({ data }) => {
        setIsFormVisibile(null);
        setTimeout(getPunch, 300);
        setFormloading(false);
      })
      .catch((err) => {
        console.log(err);
        setFormloading(false);
      });
  }

  const closePunch = () => {
    if (!formData.name || !formData.comment)
      return notification.error({
        duration: 7,
        message: "FAILED",
        description: "Some fields are missing. Make sure to fill out name and comment and try again",
      });

    setFormloading(true);

    req()
      .put(`semcompletion/v2/punches/${punch.id}/close`, formData)
      .then(({ data }) => {
        setIsFormVisibile(null);
        setTimeout(getPunch, 300);
        setFormloading(false);
      })
      .catch((err) => {
        console.log(err);
        setFormloading(false);
      });
  };

  return (
    <>
      <div className="actions row">
        <div className="left">
          <PunchStatus status={punch.status} />
        </div>
        <div className="center">
          {isFormVisible === "clear-to-open" && (
            <ButtonRounded onClick={() => setIsFormVisibile(null)} className="btn-hide-form" secondary={true}>
              <ChevronUpIcon /> Hide open punch form
            </ButtonRounded>
          )}
          {isFormVisible === "clear-to-close" && (
            <ButtonRounded onClick={() => setIsFormVisibile(null)} className="btn-hide-form" secondary={true}>
              <ChevronUpIcon /> Hide close punch form
            </ButtonRounded>
          )}
        </div>
        <div className="right">
          {/* OPEN */}
          {((!isFormVisible && punchActions["Open Punch"] && punchActions["Open Punch"].active) ||
            (!isFormVisible && !punchActions["Open Punch"])) && (
            <ButtonRounded
              color={colors.red}
              disabled={formLoading || isUploading}
              onClick={() => setIsFormVisibile("clear-to-open")}
            >
              <AccountAlertIcon style={{ marginRight: "0.5rem", width: "1.25rem", height: "1.25rem" }} /> Open punch
            </ButtonRounded>
          )}
          {((isFormVisible === "clear-to-open" && punchActions["Open Punch"] && punchActions["Open Punch"].active) ||
            (isFormVisible === "clear-to-open" && !punchActions["Open Punch"])) && (
            <ButtonRounded color={colors.red} disabled={formLoading || isUploading} onClick={() => openPunch(formData)}>
              {formLoading ? (
                <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
              ) : (
                <AccountAlertIcon />
              )}{" "}
              Open punch
            </ButtonRounded>
          )}

          {/* CLOSE */}
          {((!isFormVisible && punchActions["Close Punch"] && punchActions["Close Punch"].active) ||
            (!isFormVisible && !punchActions["Close Punch"])) && (
            <ButtonRounded
              color={colors.green}
              disabled={formLoading || isUploading}
              onClick={() => setIsFormVisibile("clear-to-close")}
              style={{ marginLeft: isTabletPortrait || isIpadMiniLandscape ? "5px" : "0.75rem" }}
            >
              <CheckIcon style={{ marginRight: "0.5rem", width: "1.25rem", height: "1.25rem" }} /> Close punch
            </ButtonRounded>
          )}
          {((isFormVisible === "clear-to-close" && punchActions["Close Punch"] && punchActions["Close Punch"].active) ||
            (isFormVisible === "clear-to-close" && !punchActions["Close Punch"])) && (
            <ButtonRounded color={colors.green} disabled={formLoading || isUploading} onClick={() => closePunch(formData)}>
              {formLoading ? (
                <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
              ) : (
                <CheckIcon />
              )}{" "}
              Close punch
            </ButtonRounded>
          )}
        </div>
      </div>

      <AnimateHeight animateOpacity={true} height={isFormVisible === "clear-to-open" ? "auto" : 0}>
        <div className="row">
          <div className="col-100">
            <FormItem label="Name" required={true}>
              <TextInput value={formData.name} onChange={(e) => onChange(e, formData)} name="name" />
            </FormItem>
          </div>
        </div>

        <div className="row">
          <div className="col-100">
            <FormItem label="Comment" required={true}>
              <TextInput
                name="comment"
                maxLength={300}
                value={formData.comment}
                onChange={(e) => onChange(e, formData)}
                showCount={true}
                style={{ height: 120 }}
                type="textarea"
              />
            </FormItem>
          </div>
        </div>

        <div className="row">
          <div className="col-100">
            <FormItem label="Images and PDFs">
              <ImageUploadMultiple
                className="image-upload"
                placeholder="Upload Images and PDFs"
                urlPrefix="/semcompletion"
                uploadedFiles={formData.image}
                onFileUpdate={(files) => setFormData({ ...formData, images: files })}
                isTabletPortrait={isTabletPortrait}
                isIpadMiniLandscape={isIpadMiniLandscape}
                getUploadState={getUploadState}
              />
            </FormItem>
          </div>
        </div>
      </AnimateHeight>

      <AnimateHeight animateOpacity={true} height={isFormVisible === "clear-to-close" ? "auto" : 0}>
        <div className="row">
          <div className="col-100">
            <FormItem label="Name" required={true}>
              <Typography.Text>{formData.name}</Typography.Text>
            </FormItem>
          </div>
        </div>

        <div className="row">
          <div className="col-100">
            <FormItem label="Comment" required={true}>
              <TextInput
                name="comment"
                maxLength={300}
                value={formData.comment}
                onChange={(e) => onChange(e, formData)}
                showCount={true}
                style={{ height: 120 }}
                type="textarea"
              />
            </FormItem>
          </div>
        </div>
      </AnimateHeight>
    </>
  );
}

const container = (props) => css`
  background-color: ${colors.white};
  padding: ${props.isFormVisible ? "0 0.85rem 0.85rem 0.85rem" : "0.85rem"};
  border-bottom: 1px ${colors.midGrey} solid;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

  .button-icon {
    margin-right: 0.35rem;
    width: 1.25rem;
    height: 1.25rem;
  }

  .image-upload label {
    margin-bottom: 0.75rem;
  }

  &.Open {
    border-left: 3px ${colors.red} solid;
  }
  &.Cleared {
    border-left: 3px ${colors.vividBlue} solid;
  }
  &.Closed {
    border-left: 3px ${colors.green} solid;
  }

  &.form-visible {
    .btn-show-form {
      opacity: 0;
      pointer-events: none;
      cursor: initial;
    }
  }
  &.form-hidden {
    .btn-hide-form {
      opacity: 0;
    }
  }

  .btn-hide-form,
  .btn-show-form {
    opacity: 1;
    transition: opacity 260ms ease;
    margin: 0 auto;
  }

  .row {
    padding: 0;
    margin: 1rem auto;
  }

  .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;

    .content {
      flex-grow: 1;
    }
  }

  .actions {
    background-color: #fff;
    position: sticky;
    top: 0;
    display: flex;
    margin-bottom: 0px;
    margin-top: ${props.isFormVisible ? "10px" : "0px"};
    align-items: center;
    padding: ${props.isFormVisible ? "10px" : "0px"};
    z-index: 1;

    .left,
    .center,
    .right {
      flex: 1 0 ${props.isTabletPortrait ? "25" : "33"}%;
    }

    .center {
      margin-right: ${props.isTabletPortrait ? "12" : "0"}px;
    }

    .right {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export default PunchSingleHeader;
