import rollbar from "rollbar";
// Libraries
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import semver from "semver";

// Other
import store from "./store";
import "./style/base-styles.css";
import "./style/route-animations.css";
import App from "./App";
import ErrorCatcher from "./components/ui/ErrorCatcher";
import registerServiceWorker from "./registerServiceWorker";
import setManifest from "./utilities/set-manifest";
import req from "./utilities/request-utility";

import { version } from "../package.json";

// First thing to do so it wont fail
setManifest();

var rollbarConfig = {
  accessToken: "68c2f57aa740403580aac83affcabc49",
  captureUncaught: true,
  autoInstrument: {
    network: true,
    log: true,
    dom: true,
    navigation: true,
    connectivity: true,
  },
  payload: {
    environment: process.env.NODE_ENV,
    code_version: version,
  },
};

const Rollbar = new rollbar(rollbarConfig);
window.Rollbar = Rollbar;

const queryClient = new QueryClient();

ReactDOM.render(
  <ErrorCatcher>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  </ErrorCatcher>,
  document.getElementById("root")
);

registerServiceWorker();

const checkAppUpdates = async () => {
  try {
    const { data } = await req()("semcompletion/applicationVersions");

    if (data.length === 0) return console.log("No app versions found");

    const storedAppVersion = localStorage.getItem("appVersion");

    if (!storedAppVersion) {
      localStorage.setItem("appVersion", version);
      return checkAppUpdates();
    }

    if (semver.gt(data[0].version, storedAppVersion)) {
      const cacheNames = await caches.keys();
      cacheNames.map((cacheName) => caches.delete(cacheName));
      localStorage.setItem("appVersion", data[0].version);

      alert("The app has been updated automatically and the cache has been cleared. The app will now reload.");
      window.location.reload();
    }
  } catch (error) {
    console.log("%c Line:63 🍐 error", "color:#7f2b82", error);
  }
};

checkAppUpdates();
